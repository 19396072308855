.main {
	height: calc(100vh - 100px);
	padding: 30px 0;
	overflow: auto;
}

.spinner {
	font-weight: normal;
	margin-left: 20px;
	font-size: 16px;
}

.table-data tr td:first-of-type {
	width: 400px;
}

.title {
	margin-bottom: 30px;
}

.doc {
	margin-bottom: 30px;
}

.phone {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.phone__controls {
	width: 190px;
	margin-left: 15px;
	flex-shrink: 0;
}

.phone__input {
	height: 36px;
	padding: 0 10px;
}

.phone-alerts :global(.alert) {
	margin-bottom: 10px;
	font-size: 12px;
	padding: 10px;
}