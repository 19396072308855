.button {
	transition: all .3s;
	position: relative;
}

.button + .button {
	margin-left: 10px;
}

.disabled {
	pointer-events: none;
	opacity: .6;
}

.spinner {
	margin-right: 10px;
	position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
}

.loading {
	white-space: nowrap;
}