.chat {
	max-width: 600px;
	margin: 0 auto;
}

.input {
	width: 100%;
	margin: 0 auto;
}

.textarea {
	padding: 5px 10px;
	height: 100px !important;
	resize: none;
}

.input :global(.custom-file-input) ~ :global(.custom-file-label)::after {
	content: "Приложить файл";
}

.messages {
	overflow: auto;
	max-height: calc(100vh - 455px);
}

.messages__item {
	margin-bottom: 3px;
}

.messages__item--my {
	text-align: right;
}

.messages__name {
	font-size: 12px;
	font-weight: 500;
}

.messages__inside {
	display: inline-block;
	background-color: #b9c5e2;
	border-radius: 5px;
	padding: 2px 12px;
	width: 75%;
}

.messages__item--my .messages__inside {
	background-color: #95e2a7;
	text-align: left;
}

.messages__item--priority .messages__inside {
	font-weight: 700;
	background-color: #ffbdc3;
	text-align: center;
	width: 100%;
}

.messages__item a {
	font-size: 12px;
	font-weight: normal;
}

.messages__date {
	font-size: 11px;
	font-weight: 500;
	margin-top: 4px;
}

button img {
	width: 40px;
	height: 50px;
	-webkit-filter: invert(1);
	filter: invert(1);
}