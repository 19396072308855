.main {
	height: calc(100vh - 100px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.wrap-pass {
	position: relative;
}

.pass-icon {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #005398;
}

.login-controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.login-controls div {
	text-align: right;
}

.login-controls a {
	font-size: 16px;
	line-height: 27px;
	color: #005398;
	display: inline-block;
}

@media screen and (max-width: 767px) {
    .main {
        margin-top: 100px;
    }
}