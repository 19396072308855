.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
}

.links {
	margin-top: 30px;
}

.links {
	text-align: center;
}

.links a {
	margin-bottom: 10px;
	width: 450px;
	text-align: center;
}

.wrap-table {
	width: 100%;
	overflow: auto;
}

.table {
	width: 100%;
	vertical-align: middle;
	text-align: center;
}

.table td {
	vertical-align: middle;
	border: 1px solid #ccc;
	padding: 10px;
}

.table tr:nth-of-type(1) td,
.table tr:nth-of-type(2) td {
	background-color: #efefef;
}