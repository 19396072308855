.main {
	height: calc(100% - 100px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	font-size: 40px;
	text-align: center;
}