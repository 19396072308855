.main {
	height: calc(100vh - 100px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.wrap-pass {
	position: relative;
}

.pass-icon {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #005398;
}

.error-msg {
	color: #dc3545;
	font-size: 12px;
	margin-top: 5px;
}