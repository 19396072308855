.id {
	max-width: 20px;
}

.name {
	max-width: 300px;
	word-wrap: break-word;
}

.inactive {
	color: rgba(0,0,0,.5);
}

.modal {
	background-color: #fff;
	display: block;
	top: 100px;
	height: calc(100vh - 100px);
}

.controls button {
	margin-left: 0 !important;
}