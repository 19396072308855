.main {
	height: calc(100vh - 100px);
	overflow: auto;
	margin: auto;
}

.technical {
	width: 100%;
}

.banner {
	height: 600px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
}

.banner::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.3);
}

.banner-title {
	font-weight: 500;
	font-size: 55px;
	line-height: 65px;
	color: #fff;
	position: relative;
	z-index: 1;
}

.banner-btn {
	margin-top: 30px;
	position: relative;
	z-index: 1;
}

.auth-danger {
	color: #fff;
	text-align: center;
	margin-top: .5rem;
	background-color: #dc3545;
	position: relative;
	z-index: 1;
}

.links {
	background-color: #F4F6FB;
	padding: 100px 0;
}

.links-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.links-block {
	margin-right: 30px;
	flex: 1 1 0; 
}

.links-block:last-of-type {
	margin-right: 0;
}

.link {
	background-color: #fff;
	display: block;
	padding: 30px;
	height: 230px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	font-weight: normal;
	font-size: 18px;
	color: #212121;
}

.link:hover {
	text-decoration: none;
}

.link span {
	display: block;
}

.link-img {
	margin-bottom: 20px;
}

.link-text {
	line-height: 28px;
}

.info {
	padding: 100px 0 60px;
	font-size: 16px;
	line-height: 24px;
	color: #000;
}

.info-title {
	font-size: 40px;
	line-height: 50px;
	color: #005398;
}

.info p {
	margin: 30px 0;
}

.info-list {
	margin: 0;
	padding: 0;
}

.info-list li {
	list-style: none;
	padding: 0 0 0 38px;
	margin-bottom: 30px;
	position: relative;
}

.info-list li:after {
	content: '';
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #005398;
	top: 9px;
	left: 0;
}

.info-list li:last-of-type {
	margin-bottom: 0;
}

