@font-face {
	font-family: 'PTRoot';
	src: url('/fonts/PTRoot_Light.eot');
	src:
	     url('/fonts/PTRoot_Light.eot?#iefix') format('embedded-opentype'),
	     url('/fonts/PTRoot_Light.woff2') format('woff2'),
	     url('/fonts/PTRoot_Light.woff') format('woff'),
	     url('/fonts/PTRoot_Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'PTRoot';
	src: url('/fonts/PTRoot_Regular.eot');
	src:
	     url('/fonts/PTRoot_Regular.eot?#iefix') format('embedded-opentype'),
	     url('/fonts/PTRoot_Regular.woff2') format('woff2'),
	     url('/fonts/PTRoot_Regular.woff') format('woff'),
	     url('/fonts/PTRoot_Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PTRoot';
	src: url('/fonts/PTRoot_Medium.eot');
	src:
	     url('/fonts/PTRoot_Medium.eot?#iefix') format('embedded-opentype'),
	     url('/fonts/PTRoot_Medium.woff2') format('woff2'),
	     url('/fonts/PTRoot_Medium.woff') format('woff'),
	     url('/fonts/PTRoot_Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'PTRoot';
	src: url('/fonts/PTRoot_Bold.eot');
	src:
	     url('/fonts/PTRoot_Bold.eot?#iefix') format('embedded-opentype'),
	     url('/fonts/PTRoot_Bold.woff2') format('woff2'),
	     url('/fonts/PTRoot_Bold.woff') format('woff'),
	     url('/fonts/PTRoot_Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

html, body {
	height: 100%;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'PTRoot';
	font-weight: normal;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

button {
	outline: none;
}

#root {
	height: 100%;
}

.App {
	height: 100%;
}

.Routes {
	height: 100%;
}

.form-control {
	width: 100%;
	margin-right: 0;
}

.react-datepicker__input-container .form-control {
	border-radius: .25rem 0 0 .25rem;
	margin-right: 0;
	width: 100%;
}

.react-datepicker-wrapper {
	width: calc(100% - 40px);
}

.react-datepicker-popper {
	z-index: 10 !important;
}

.navbar {
	padding: 0;
}

.nav {
	margin-bottom: 20px !important;
}

.nav-link,
.page-link {
	cursor: pointer;
}

.h5 {
	margin-top: 30px;
}

.custom-file .custom-file-label {
	white-space: nowrap;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
}

.custom-control-label {
	cursor: pointer;
}

.custom-control.custom-checkbox-simple {
	width: 0;
	padding-left: 30px;
	margin: 0 auto;
}

.wrap-spinner {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-file .custom-file-label::after,
.input-group-text {
	transition: background-color .3s;
}

.custom-file:hover .custom-file-label::after {
	background-color: #cfd5da;
}

.input-group-text:hover {
	background-color: #cfd5da;
}

.btn-app {
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
	padding: 13px 29px;
	color: #fff;
	background-color: #005398;
	border: none;
	display: inline-block;
}

.btn-app:hover {
	background-color: #005398;
	color: #fff;
	text-decoration: none;
}

.btn-app-forward {
	padding-right: 53px;
	position: relative;
}

.btn-app-sm {
	font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
}

.btn-app-forward::after {
	content: '';
	width: 8px;
	height: 14px;
	position: absolute;
	top: 50%;
	margin-top: -7px;
	right: 29px;
	background: url(/images/arrow.svg) 0 0 no-repeat;	
}

label {
	font-size: 16px;
	line-height: 24px;
	color: #99A4B9;
	margin-bottom: 10px;
}

.form-group {
	margin-bottom: 30px;
}

.form-control {
	height: 50px;
	background-color: #F4F6FB;
	border: none;
	border-radius: 0;
	line-height: 24px;
	font-size: 16px;
	color: #000;
	padding: 0 24px;
	border: 1px solid transparent;
}

.form-control:focus {
    color: #000;
    background-color: #F4F6FB;
    border-color: transparent;
    box-shadow: none;
}

.form-control::placeholder {
	color: #99A4B9;
}

.password-view {
	padding-right: 40px;
}

.custom-checkbox .custom-control-label {
	color: #212121;
	font-size: 16px;
	line-height: 24px;
	padding-left: 16px;
}

.custom-control-input {
	width: 26px;
	height: 26px;
	outline: 0;
	box-shadow: none;
}

.custom-checkbox .custom-control-label:focus {
	outline: 0;
	box-shadow: none;
}

.custom-checkbox .custom-control-label::after {
	box-sizing: border-box;
	background: #FFFFFF;
	border: 1px solid #D5DDEC;
	width: 26px;
	height: 26px;
	border-radius: 0;
	top: 0;
	outline: 0;
	box-shadow: none;
}

.custom-checkbox .custom-control-label::before:focus,
.custom-checkbox .custom-control-label::after:focus {
	outline: 0;
	box-shadow: none;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}

.custom-checkbox .custom-control-label::before {
	top: 0;
	margin: 7px 0 0 4px;
	width: 18px;
	height: 13px;
	background-image: url(/images/check.svg);
	opacity: 0;
	position: absolute;
	z-index: 1;
	border: none;
	transition: opacity .3s;
	background-color: transparent !important;
	outline: none !important;
	box-shadow: none !important;
}

.custom-control-input:checked~.custom-control-label::before {
	opacity: 1;
}

.alert {
	margin-bottom: 30px;
	border-radius: 0;
}

.form {
	width: 540px;
	border: 1px solid #D5DDEC;
}

.form-title {
	font-size: 32px;
	line-height: 44px;
	color: #fff;
	padding: 18px 65px;
	background-color: #005398;
}

.form-body {
	padding: 49px 65px;
}

.page-title {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.page-title .spinner-grow {
	margin-left: 10px;
}

.hint {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	vertical-align: middle;
	color: #000;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #000;
	font-size: 11px;
	font-weight: bold;
}

.hint-text {
	padding: 8px;
	background-color: #e8e8e8;
	font-size: 12px;
	max-width: 400px;
	border: 1px solid #e8e8e8;
	border-radius: 10px;
	line-height: 1.3;
}

.react-hint--right::after {
	border-right-color: #e8e8e8 !important;
}

.react-hint--left::after {
	border-left-color: #e8e8e8 !important;
}

.react-hint--bottom::after {
	border-bottom-color: #e8e8e8 !important;
}

.react-hint--top::after {
	border-top-color: #e8e8e8 !important;
}