.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
}

.links {
	margin-top: 30px;
}

.links {
	text-align: center;
}

.links a {
	margin-bottom: 10px;
	width: 450px;
	text-align: center;
}