.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
}

.links {
	margin-top: 30px;
}

.links {
	text-align: center;
}

.links a {
	margin-bottom: 10px;
	width: 450px;
	text-align: center;
}

.table {
	display: table;
	border: 1px solid #dee2e6;
}

.table-row {
	display: table-row;
}

.table-cell {
	display: table-cell;
	padding: 10px;
	border-top: 1px solid #dee2e6;
	vertical-align: middle;
}

.table-cell:first-of-type {
	text-align: center;
}

.table-row:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

.table input {
	border: 1px solid #dee2e6;
}