.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
}

.registration {
	width: 100%;
}

.title {
	margin-bottom: 30px;
}

.btn-reset {
	margin-left: 15px;
}

.error-field {
	border-color: #dc3545;
}

.error-msg {
	color: #dc3545;
	font-size: 12px;
	margin-top: 5px;
}

:global(.custom-file-input) ~ :global(.custom-file-label)::after {
    content: "Загрузить";
}

:global(.form-group) {
	position: relative;
}

.suggestions {
	position: absolute;
	top: 100%;
	z-index: 10;
	left: 0;
	width: 100%;
	background-color: #fff;
    border: 1px solid #ced4da;
    max-height: 290px;
    overflow: auto;
}

.suggestions-item {
    padding: .375rem .75rem;
    cursor: pointer;
    transition: background-color .3s;
}

.suggestions-item:hover {
	background-color: #f8f9fa;
}

.info {
	font-size: 12px;
}

.info.error-field {
	color: #dc3545;
}

.red-star {
	color: #dc3545;
}

.red-star--label {
	display: inline-block;
	margin-left: 5px;
}

.wrap-pass {
	position: relative;
}

.pass-icon {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.disabled {
	opacity: .4;
	pointer-events: none;
}

:global(.nav-link).success {
	padding-right: 30px;
	position: relative;
}

.nav-item-svg {
	position: absolute;
	right: 6px;
	top: 50%;
	margin-top: -11px;
	color: #28a745;
}

.suggestions-title {
	color: #fd7e14;
	padding: .375rem .75rem;
}