.department {
	width: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

.name {
	max-width: 300px;
	word-wrap: break-word;
}