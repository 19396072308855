.layout {
	height: 100%;
}

.navbar {
	height: 100px;
}

.navbar a {
	color: #212121;
	font-size: 14px;
	font-weight: 500;
}

.nav-item svg {
	margin-right: 5px;
}

.navbar .login {
	color: #005398;
}

.login img {
	margin-right: 10px;
	margin-top: -2px;
}

.nav {
	width: 100%;
	display: flex;
}

.navbar-nav a {
	padding: 0 !important;
	margin-right: 50px;
	position: relative;
	height: 24px;
	line-height: 24px;
}

.navbar-nav a::after {
	content: '';
	position: absolute;
	right: -50px;
	top: 0;
	height: 24px;
	width: 1px;
	background-color: #D8D8D8;
}

.navbar-nav .nav-item:last-of-type a {
	margin-right: 0;
	margin-left: 50px;
}

.navbar-nav .nav-item:last-of-type a::after {
	content: none;
}

.logo {
	/*display: block;*/
}

.logo img {
	width: 200px;
	height: 50px;
}

@media screen and (max-width: 767px) {
	.navbar-nav {
        flex-direction: row;
        width: 100%;
        justify-content: center;
	}
	
	.nav-item {
        /* margin-left: 15px; */
        /* padding: 0 10px; */
    }
    
    .nav {
        justify-content: center !important;
    }
	
	.nav-item a span {
		/* display: none; */
    }

    .nav-item a::after {
        content: none;
    }

    .nav-item a {
        margin-left: 0 !important;
    }
    
    .navbar :global(.navbar-toggler) {
        display: none;
    }
    
    .navbar :global(.navbar-brand) {
        display: block;
        text-align: center;
        margin-right: 0;
        width: 100%;
    }
    .login img {
        display: none;
    }
}