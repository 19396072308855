.red-star {
	color: #dc3545;
}

:global(.custom-file-input) ~ :global(.custom-file-label)::after {
    content: "Загрузить";
}

.main--order {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.main--order .btn--add {
    order: 2;
    margin-left: 10px;
}

.main--order .inputs {
    order: 1;
    flex-grow: 1;
}

.info {
	font-size: 12px;
}

.info.error-field {
	color: #dc3545;
}

.error-field {
	border-color: #dc3545;
}

.error-msg {
    color: #dc3545;
    font-size: 12px;
    margin-top: 5px;
}

.input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.input:first-of-type {
    margin-top: 0;
}

.btn {
    font-size: 22px;
    padding-top: 0;
    padding-bottom: 0;
    height: 38px;
    line-height: 38px;
    width: 38px;
    margin-bottom: 10px;
}

.btn--remove {
    line-height: 14px;
    font-size: 30px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 0;
    padding-bottom: 5px;
}