.main {
	height: calc(100vh - 100px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.verify {
	width: 540px;
	border: 1px solid #D5DDEC;
}

.verify-title {
	font-size: 32px;
	line-height: 44px;
	color: #fff;
	padding: 18px 65px;
	background-color: #005398;
	display: flex;
	align-items: center;
	justify-content: center;
}

.verify-text {
	font-size: 20px;
	text-align: center;
}

.verify-body {
	padding: 49px 65px;
}

.spinner {
	margin-top: 20px;
}