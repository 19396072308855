.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
}

.technical {
	width: 100%;
	padding: 0 20px;
}

.main :global(.custom-select){
	height: 50px;
}

.title {
	margin-bottom: 30px;
}

.error-field {
	border-color: #dc3545;
}

.error-group {
	border: #dc3545 solid 1px;
}
.error-msg {
	color: #dc3545;
	font-size: 12px;
	margin-top: 5px;
}

:global(.custom-select.custom-select--voltage) {
	height: 50px;
}

:global(.custom-file-input) ~ :global(.custom-file-label)::after {
    content: "Загрузить";
}

:global(.form-group) {
	position: relative;
}

.suggestions {
	position: absolute;
	top: 100%;
	z-index: 10;
	left: 0;
	width: 100%;
	background-color: #fff;
    border: 1px solid #ced4da;
    max-height: 290px;
    overflow: auto;
}

.suggestions-item {
    padding: .375rem .75rem;
    cursor: pointer;
    transition: background-color .3s;
}

.suggestions-item:hover {
	background-color: #f8f9fa;
}

.table th {
	vertical-align: middle;
}

.table td {
	text-align: center;
	vertical-align: middle;
}

.technical {
	width: calc(100% - 300px);
	margin-right: 0;
	margin-left: 0;
}

.statusForm {
	position: fixed;
	right: 0;
	top: 100px;
	padding: 0 40px 0 0;
	width: 300px;
}

.statusForm .title {
	margin-top: 0;
}

.nav-link {
	position: relative;
}

.nav-link.success {
	padding-right: 30px;
}

.nav-link.error {
	color: #dc3545 !important;
	padding-right: 1rem !important;
}

.nav-link.error .nav-item-svg {
	display: none;
}

.nav-item-svg {
	position: absolute;
	right: 6px;
	top: 50%;
	margin-top: -11px;
	color: #28a745;
}

.statusForm :global(.alert) {
	font-size: 14px;
	line-height: 1.2;
	margin-bottom: 0;
}

.statusForm :global(.alert) div {
	margin: 7px 0;
}

.messages {
	max-height: calc(100vh - 170px);
	overflow: auto;
}

.doclink {
	display: flex;
	align-items: center;
	justify-content: space-between;
	line-height: 1;
}

.doclink a {
	display: block;
}

.doclink button {
	margin-top: -4px;
	margin-left: 5px;
	outline: none;
}

.add-deadline svg {
	margin-right: 5px;
}

.main input {
	margin-right: 0;
	width: 100%;
}

.suggestions-label {
	color: #fd7e14;
	font-size: 12px;
}

.info {
	font-size: 12px;
}

.disabled {
	opacity: .4;
	pointer-events: none;
}

.red-star {
	color: #ff0000;
}

.custom-control-label-radio {
	width: 200px;
	flex-shrink: 0;
}

.suggestions-title {
	color: #fd7e14;
	padding: .375rem .75rem;
}

.power-block {
	flex-direction: column;
    justify-content: space-between;
}

.category {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.category input {
	width: 150px;
	margin: 0 10px;
}

.category label {
	width: 92px;
}

.main :global(.hint) {
    margin-left: 5px;
}

/**
 * Main wrapper
 */
.select-search {
	width: 300px;
	position: relative;
	font-family: 'Nunito Sans', sans-serif;
	box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
	box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
	position: relative;
	z-index: 1;
}

.select-search__value::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: calc(50% - 9px);
	right: 19px;
	width: 11px;
	height: 11px;
}

/**
 * Input
 */
.select-search__input {
	display: block;
	height: 36px;
	width: 100%;
	padding: 0 16px;
	background: #fff;
	border: 1px solid transparent;
	box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	outline: none;
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
	text-align: left;
	text-overflow: ellipsis;
	line-height: 36px;
	-webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
	-webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
	cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
	background: #fff;
	box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
	list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
	border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option {
	display: block;
	height: 36px;
	width: 100%;
	padding: 0 16px;
	background: #fff;
	border: none;
	outline: none;
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
	text-align: left;
	cursor: pointer;
}

.select-search--multiple .select-search__option {
	height: 48px;
}

.select-search__option.is-selected {
	background: #2FCC8B;
	color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
	background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
	background: #2eb378;
	color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
	font-size: 10px;
	text-transform: uppercase;
	background: #eee;
	padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
	opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
	background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
	cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
	border-radius: 3px;
	overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
	transform: rotate(45deg);
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
	pointer-events: none;
}

.select-search--multiple .select-search__input {
	cursor: initial;
}

.select-search--multiple .select-search__input {
	border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
	cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
	border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
	position: absolute;
	z-index: 2;
	top: 44px;
	right: 0;
	left: 0;
	border-radius: 3px;
	overflow: auto;
	max-height: 360px;
}

.select-search--multiple .select-search__select {
	position: relative;
	overflow: auto;
	max-height: 260px;
	border-top: 1px solid #eee;
	border-radius: 0 0 3px 3px;
}