.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
	position: relative;
}

.wrap-table {
	overflow: auto;
}

.title {
	margin-bottom: 30px;
}

.table-data td {
	vertical-align: middle;
}

.table-data tr {
	cursor: pointer;
}

.table-data tr td:first-of-type {
	width: 20px;
}

.control {
	text-align: center;
}

.modal--active {
	display: block;
}

.department {
	width: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

:global(.modal-body) {
	overflow: auto;
    max-height: calc(100vh - 200px);
}

.towns {
	max-width: 600px;
}

.towns span {
	display: inline-block;
	margin: 0 5px;
}

.new-town {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.new-town button {
	margin-left: 10px;
	font-size: 30px;
	height: 48px;
	line-height: 48px;
	padding: 0 10px;
}

.suggestions {
	width: 100%;
	background-color: #fff;
    border: 1px solid #fd7e14;
	margin-top: 10px;
}

.suggestions-item {
    padding: .375rem .75rem;
    cursor: pointer;
    transition: background-color .3s;
}

.suggestions-item:hover {
	background-color: #f8f9fa;
}

.suggestions-loading {
	width: 20px;
	height: 20px;
	margin: 10px auto 0;
	border-radius: 50%;
	border-style: solid;
	border-width: 1px;
	border-color: transparent #000 transparent #000;
	animation: load 1s linear infinite;
}

@keyframes load {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pagination {
    flex-wrap: wrap;
}