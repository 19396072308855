.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
	position: relative;
}

.wrap-table {
	overflow: auto;
}

.title {
	margin-bottom: 30px;
	display: flex;
	align-items: center;
}

.title a {
	margin-right: 10px;
}

.table-data td {
	vertical-align: middle;
}

.table-data tr td:first-of-type,
.table tr td:first-of-type {
	width: 400px;
}

/*.table-data.address tr td:first-of-type,*/
/*.table tr td:first-of-type {*/
/*	width: 100px;*/
/*}*/

.table.address tr td:first-of-type
{
	width: 100px;
}


.modal--active {
	display: block;
}

.docs__item {
	margin-bottom: 15px;
}

.docs__label {
	margin-bottom: 5px;
}

.docs__field {
	display: flex;
}

.docs__field :global(.btn-primary) {
	color: #fff;
}

.docs__warning {
	color:#fd7e14;
}

.main * + :global(.h4) {
	margin-top: 30px;
}

.unviewed {
	border-radius: 20px;
	background-color: #28a745;
	height: 20px;
	min-width: 20px;
	text-align: center;
	line-height: 20px;
	color: #fff;
	display: inline-block;
	font-size: 12px;
	margin-left: 5px;
}

.unviewed-btn {
	box-shadow: 0 0 10px rgba(40, 167, 69, 1);
}

.long-btn {
	white-space: nowrap;
}

.paycheck {
	border-bottom: 1px solid #ccc;
	padding: 20px 0;
}

.paycheck :global(.alert) {
	margin-bottom: 0;
}

/* NEW STYLES */

.payment-info {
	font-size: 16px;
	color: #000;
	padding: 20px 0 30px;
	border-bottom: 1px solid #cccccc;
	display: flex;
}

.payment-info__right,
.payment-info__left {
	border-bottom: 1px solid #dee2e6;
	border-top: 1px solid #dee2e6;
	padding: 15px 15px 25px;
	background-color: #f2f2f2;
}

.payment-info__left {
	border-right: 1px solid #dee2e6;
}

.payment-info__title {
	font-size: 18px;
	font-weight: 600;
	color: #000;
}

.text-link {
	color: #007bff;
	font-weight: 600;
}

.payment-info__term {
	display: flex;
	margin-top: 15px;
}

.payment-info__term-title {
	width: 190px;
	margin-right: 20px;
	flex-shrink: 0;
}

.payment-info__images {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.payment-info__image {
	margin-right: 30px;
}

@media screen and (max-width: 1050px) {
	.payment-info {
		display: block;
	}
}

@media screen and (max-width: 768px) {
	.payment-info__term,
	.payment-info__images {
		display: block;
	}
}