.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
}

.title {
	margin-bottom: 30px;
	line-height: 50px;
}

.table {
	display: table;
	margin-bottom: 0;
}

.table .table-row {
	display: table-row;
	cursor: pointer;
	transition: background .3s;
}

.table .table-row:first-of-type {
	background-color: #d2d2d2;
}

.table .table-row.table-unviewed{
	background-color: #f1f1f1;
}

.table .table-cell {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	position: relative;
	padding: 7px 8px;
	font-size: 12px;
	border-bottom: 1px solid #d2d2d2;
}

.table .table-row:last-of-type .table-cell {
	border-bottom: none;
}

.table .table-cell button,
.table .table-cell a {
	font-size: 12px;
}

.table-cell--number {
	text-align: center;
}

.table-cell--number .input {
    font-size: 15px;
    width: auto !important;
}

.table-cell--number .input.unsized {
    width: 100% !important;
}

.table-cell--statuses button,
.table-cell--number button,
.table-cell--date button {
	margin-top: 5px;
}

.main :global(.custom-select) {
	width: 200px;
	font-size: 12px;
}

.address {
	text-align: left;
	width: 300px;
}

.paycheck {
	text-align: left;
	width: 230px;
}

.paycheck div, .address div {
	white-space: nowrap;
	display: inline-block;
}

.paycheck div::after, .address div::after {
	content: '\2044';
	display: inline-block;
	vertical-align: bottom;
	margin-left: 4px;
	margin-right: 4px;
}

.paycheck div:last-of-type::after, .address div:last-of-type::after {
	content: none;
}

.sorted::after {
	content: '\2193';
	display: inline-block;
	font-weight: bold;
	position: absolute;
	bottom: -3px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 20px;
	transition: transform .3s;
	color: #007bff;
}

.sorted.sort-desc::after {
	content: '\2193';
}

.sorted.sort-asc::after {
	content: '\2191';
}

.docs div {
	white-space: nowrap;
}

.dropdown-menu-opened {
	display: block;
	min-width: 450px;
}

.dropdown-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dropdown :global(.dropdown-item:active) {
	background-color: transparent;
	color: #000;
}

.main :global(.pagination .active),
.main :global(.pagination .disabled) {
	pointer-events: none;
}

.controls {
	display: flex;
	align-items: center;
}

.controls .dropdown {
	margin-right: 10px;
}

.popup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: auto;
	z-index: 1;
	background-color: rgba(0,0,0,.3);
}

.popup-inside {
	margin: 30px;
	background-color: #fff;
}

.close {
	position: absolute;
    z-index: 2;
	right: 60px;
	top: 60px;
}

.close span span {
	font-size: 40px;
}

.textarea {
	width: 200px !important;
	font-size: 12px;
	padding: 5px 10px !important;
	margin-bottom: 0;
}

.input {
	padding: 0 5px;
	width: 60px !important;
	text-align: center;
	display: inline-block;
	margin-right: 0 !important;
	font-size: 12px;
	height: 30px;
}

.table {
	position: relative;
}

.loading-sort {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.2);
	z-index: 10;
}

.loading-sort .spinner {
	width: 30%;
	height: 30%;
}

.table .table-annul {
	background-color: #ffbdc3 !important;
}

.modal--active {
	display: block;
}

.dropdown-item .filter-item {
	width: 500px !important;
	display: flex;
}

.main :global(.react-datepicker__input-container .form-control) {
	margin-right: 0;
    border-radius: .25rem;
}

.main :global(.react-datepicker-wrapper) {
	width: 100%;
}

.filter-item :global(.btn) {
	margin-left: 10px;
}

.filter-item :global(.custom-select) {
	width: 180px;
}

.filter-field {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.filter-item .filter-field :global(.custom-select) {
	width: 100%;
	margin-right: 10px;
}

.filter-field :global(.btn) {
	margin-left: 0 !important;
	width: 100%;
	margin-right: 10px;
}

.filter-field :global(.btn-group .btn) {
	margin-right: 0;
	margin-left: -1px !important;
}

.filter-field :global(.btn-group) {
	margin-right: 10px;
}

.main :global(.dropdown-item:hover) {
	background-color: transparent;
}

.main :global(.custom-control.custom-checkbox-simple) {
	margin: 0;
	padding-left: 21px;
	display: inline-block;
}

.main :global(.form-control) {
	width: calc(100% - 10px);
}

.dropdown-item-controls > .main :global(.btn) {
	margin-left: 10px;
}

.annul-wrap {
	position: relative;
}

.annul-status {
	font-size: 12px;
}

.pagination-load {
	pointer-events: none;
}

.main :global(.form-group) {
	margin-bottom: 10px;
}

.date {
	width: 120px;
}

.date :global(.form-control) {
	margin-right: 0 !important;
	width: 100%;
	padding: 0 0 0 10px;
	font-size: 12px;
	height: 30px;
}

.date :global(.react-datepicker-wrapper) {
	width: calc(100% - 40px);
}

.date :global(.input-group-text) {
	border-color: #fff;
	border-left-color: #F4F6FB;
	background-color: #F4F6FB;
}

.unviewed {
	border-radius: 20px;
	background-color: #28a745;
	height: 20px;
	min-width: 20px;
	text-align: center;
	line-height: 20px;
	color: #fff;
}

.warning {
	border-radius: 50%;
	background-color: #a0a0a0;
	height: 20px;
	width: 20px;
	line-height: 20px;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	color: #fff;
}

.table-wrap {
    margin: 30px 0;
}