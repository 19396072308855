.main {
	height: calc(100vh - 100px);
	overflow: auto;
	padding: 30px 0;
	margin: auto;
	position: relative;
}

.wrap-table {
	overflow: auto;
}

.title {
	margin-bottom: 30px;
}

.table-data td {
	vertical-align: middle;
}

.table-data tr {
	cursor: pointer;
}

.table-data tr td:first-of-type {
	width: 20px;
}

.control {
	text-align: center;
}

.modal--active {
	display: block;
}

:global(.modal-body) {
	overflow: auto;
    max-height: calc(100vh - 200px);
}